import { Flex, Link, Spinner, Text, useColorMode } from '@chakra-ui/react'
import { LogoHorizontal, Refresh } from './Icon'
import { useEffect, useState } from 'react'
import NextLink from 'next/link'
import Image from 'next/image'

interface Props {
  text?: string
}

export default function SplashScreen({ text }: Props) {
  const { colorMode } = useColorMode()
  const [staleTime, setStaleTime] = useState(false)

  useEffect(() => {
    setTimeout(() => setStaleTime(true), 8000)
  }, [])

  return (
    <Flex
      as={'section'}
      flex={1}
      align={'center'}
      justify={'center'}
      gap={'24px'}
      flexDirection={'column'}
      h={'100vh'}
      bgColor={`${colorMode}.primary.container`}
      color={`${colorMode}.primary.onContainer`}
    >
      <Image
        src={`${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/logo-gptmax.svg`}
        width={230}
        height={80}
        alt="Logo GPT MAX"
      />
      <Text as={'h1'} color={`${colorMode}.primary.onContainer`}>
        {text ? text : 'Aguarde...'}
      </Text>
      <Spinner />
      {staleTime && (
        <Flex
          className="fade-in-image"
          flexDirection={'column'}
          gap={'12px'}
          align={'center'}
          maxW={340}
        >
          <Text fontSize={'sm'} textAlign={'center'}>
            Parece que isto está levando mais tempo do que esperavamos, clique
            em Dashboard.
          </Text>
          <Flex align={'center'} gap="8px">
            <Refresh w="24px" h="24px" />
            <Link textDecoration={'underline'} as={NextLink} href={'/login'}>
              Atualizar página
            </Link>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
