import { Tenants } from '@/interfaces/Tenants'
import { colorsDark, colorsLight } from '@/styles/colors'

export const DEFAULT_TENANT: Tenants = {
  name: 'gptmax',
  logo_extended: null,
  cnpj: '',
  id: 0,
  theme: colorsLight,
  theme_dark: colorsDark,
  image_background: '',
  url: '',
  logo: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/logo-gptmax.svg`,
  powered_by: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/logo-abstrato.svg`
}

export const APPS = [
  // studios
  {
    title: 'Estudio de Imagem',
    subtitle: 'Crie imagens incríveis para redes sociais',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/apps/imagestudio.webp`,
    route: '/apps/image-gallery',
    category: 'studios',
    isPop: false
  },
  {
    title: 'Estudio de Vídeo',
    subtitle: 'Crie vídeos incríveis para redes sociais',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/apps/videostudio.webp`,
    route: '/apps/video-studio',
    category: 'studios',
    isPop: false
  },
  {
    title: 'Estudio de Áudio',
    subtitle: 'Gere Audio para podcast, vídeos',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/apps/audiostudio.webp`,
    route: '/apps/voice-studio',
    category: 'studios',
    isPop: false
  },
  // marketing
  {
    title: 'Posts para Redes Sociais',
    subtitle: 'Gere posts incriveis',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/cover-post.jpg`,
    route: '/apps/posts',
    category: 'marketing',
    isPop: false
  },
  {
    title: 'Roteiros',
    subtitle: 'Para vídeos, entrevistas, etc',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/cover-script.jpg`,
    route: '/apps/scripts',
    category: 'marketing',
    isPop: false
  },
  {
    title: 'Crie seus anúncios',
    subtitle: 'Crie anúncios incríveis para suas campanhas',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/cover-ads.jpg`,
    route: '/apps/advertisements',
    category: 'marketing',
    isPop: false
  },
  {
    title: 'Artigos',
    subtitle: 'Para blog, sites, etc',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/cover-article.jpg`,
    route: '/apps/articles',
    category: 'marketing',
    isPop: false
  },

  {
    title: 'E-mail Marketing',
    subtitle: 'Gere copy para e-mail marketing',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/cover-email.jpg`,
    route: '/apps/email-marketing',
    category: 'marketing',
    isPop: false
  },
  // productivity
  {
    title: 'Doc Wizard',
    subtitle: 'Converse com seus documentos e crie resumos',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/apps/docwizard.webp`,
    route: '/apps/doc-wizard',
    category: 'productivity',
    isPop: false
  },
  {
    title: 'Mago do YouTube',
    subtitle: 'Converse com videos do YouTube',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/apps/magoyoutube.webp`,
    route: '/apps/video-magician',
    category: 'productivity',
    isPop: false
  },
  {
    title: 'AI Researcher',
    subtitle: 'Smart AI Researcher',
    imagePath: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/apps/airesearcher.webp`,
    route: 'https://www.morphic.sh/',
    category: 'productivity',
    isPop: true
  }
]
